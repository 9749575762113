export const routes = [
    {
        path: '/currencies',
        name: 'currencies.browse',
        component: () => import(/* webpackChunkName: "CurrenciesBrowse" */ '@/views/app/CurrenciesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/currencies/create',
        name: 'currencies.create',
        component: () => import(/* webpackChunkName: "CurrenciesActions" */ '@/views/app/CurrenciesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/currencies/:id/edit',
        name: 'currencies.edit',
        component: () => import(/* webpackChunkName: "CurrenciesActions" */ '@/views/app/CurrenciesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/currencies/:id/delete',
        name: 'currencies.delete',
        component: () => import(/* webpackChunkName: "CurrenciesActions" */ '@/views/app/CurrenciesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]